@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Poppins, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* App.css */
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  width: 100%;
  overflow-y: auto; /* Ensure vertical scrollbar appears when content overflows */
  
}

/* ::-webkit-scrollbar-track:hover
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
} */
::-webkit-scrollbar {
  z-index: 9999;
  width: 12px;
  height:0px;
	background-color: transparent;
}

/* ::-webkit-scrollbar-thumb {
  border-radius: 10px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); 
	background-color: #555;
}*/

/* ::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover color 
} */ 
.css-1143eue-MuiButtonBase-root-MuiIconButton-root:hover{
  background-color: transparent  !important;
}
.floatingButton{
  position: absolute !important;
    top: 50%;
    right: 0;
    z-index: 9999999999
}
@media (min-width: 600px) {

.css-1mazmjs-MuiToolbar-root{
  padding-left: 5% !important;
  padding-right: 5% !important;
}
.css-c9tqp5{
  padding-left: 60px !important;
  padding-right: 60px !important;
}
}

img, video {
  max-width: none !important;
  
}

.fa-minus:before {
  content:"\f068";
}
.fa-plus:before{
  content:"\f067"
}

@media screen and (max-width: 820px){
  .map-padding{
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .top_banner_image{
    margin:0 !important
  }
  .top_banner_image > div > div {
    margin: 0 !important;
  }

  
}


.company-updates{
  background: linear-gradient(95.05deg, #0B3255 15.96%, #DB1F51 98.4%);
width:100%;
padding:20px;
height:100%;
/* padding-top: 20px; */
}


a.hover-link {
  color: #000;
  text-decoration: none; /* Optional */
}

a.hover-link:hover {
  color: #DB1F51;
  cursor: pointer;
}

.top_banner_image img{
  width:100% !important;
}
/* .top_banner_image {
  margin-left:50px !important;
  margin-right:50px !important
} */
.top-banner-slider .slick-slide div{
  margin-left:3px;
  margin-right:3px

}

.mobile-usps .slick-track{
  height: 200px !important;
}
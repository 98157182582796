.enquire-casestudy {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: white;
    padding: 0;
    border-radius: 80px;
    border: none;
    background: linear-gradient(45deg, #2587e4 30%, #e1345d 90%);
    margin-top: 20px;
    height: 41px;
    width: 128px;
    padding: 10px 10px;
}

.slider-wrapper .slick-slide {
    margin-right: -5px; /* Adjust as needed */
}

.slider-wrapper .slick-slide:first-child {
    margin-right: 0;
}

.slider-wrapper {
    margin-bottom: 4rem; /* Adjust the distance between heading and slider */
}

.slick-dots {
    bottom: -4rem;
  }
  
  @media (max-width: 768px) { /* Adjust the breakpoint for your mobile size */
    .slick-dots {
      display: none;
    }
  }
  




.case-study-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    border: 1px solid gray;
    background-color: #ffffff !important;
}
  
.case-study-slider .slick-dots li button .slick-active {
    content: none !important;
    border-radius: 50%;
    color: #db1f51 !important;
    background: #cad4e7 !important;
    opacity: 1;
    height: 18px !important;
    width: 18px !important;
}

.case-study-slider .slick-dots li.slick-active {
    width: 28px !important;
}

.case-study-slider .slick-dots .slick-active button {
    content: none !important;
    border-radius: 16px !important;
    background: #db1f51 !important;
    opacity: 1;
    color: #db1f51 !important;
    height: 18px !important;
    width: 28px !important;
}

.case-study-slider .slick-dots li button:before {
    content: none !important;
    color: #cad4e7 !important;
    opacity: 1;
    color: #db1f51 !important;
}

.download-casestudy-container {
    background: linear-gradient(92.08deg, #2587e4 0.69%, #e1345d 120.93%);
    border-radius: 80px;
    height: 41px;
    width: 128px;
    padding: 1px;
    margin-top: 20px;
}

.download-casestudy {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #000;
    background-color: #fff;
    border-radius: 80px;
  
    height: 100%;
    width: 100%;
    padding: 10px 10px;
}
:root {
  --primary-tab-color: #db1f51;
  --primary-background-color: #ffffff;
}

.position-absolute {
  position: absolute;
}

.background-secondary {
  background-color: #e7ecf4;
}

.carousel {
  height: 700px;
  top: -550px;
  left: 60%;
  position: absolute;
}
.fancy-carousel-wrapper-element {
  /* transform: rotate(270deg); */
  position: relative;
}
.fancy-carousel-navigators {
  gap: 0px !important;
  margin-left: 0px !important;
  position: absolute;
  /* left: -1105px !important; */
  /* bottom: 236px !important; */
  transform: rotate(90deg);
  top: -60px;
  left: -8%;
}

.fancy-carousel-element img {
  /* transform: rotate(90deg); */
}

.fancy-carousel-navigation-button:first-child {
  position: absolute;
  transform: rotate(90deg);
  color: #000 !important;
  background-color: white !important;
  border: 1px solid #e6e6e6 !important;
  margin-right: 10px;
  top: -45px;
  left: -46px;
}

.fancy-carousel-navigation-button:last-child {
  transform: rotate(-90deg);
  color: #000 !important;
  background-color: white !important;
  border: 1px solid #e6e6e6 !important;
  top: -45px;
  left: 50px;
  position: absolute;
}

/* Hompage slider css start */
.slick-next:before {
  font-size: 35px;
  opacity: 1;
  bottom: 30px;
  display: none;
}

.top-slider .slick-prev:before,
.slick-next:before {
  font-size: 35px;
  opacity: 1;
  bottom: 30px;
  display: none;
}

.testimonial-slider-container .slick-prev:before,
.slick-next:before {
  font-size: 35px;
  opacity: 1;
  bottom: 30px;
}

.mt-3 {
  margin-top: 3px;
}

.top-slider .slick-prev,
.top-slider .slick-next {
  top: 50%;
  margin-left: 30px;
}

.testimonial-slider-container .slick-prev,
.testimonial-slider-container .slick-next {
  top: 50%;
  margin-left: 30px;
}

.top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
  max-width: 10%;
  margin-left: 10px;
  margin-right: 10px;
}
.dmsans {
  font-family: "DM Sans";
}
.opensans {
  font-family: "Open Sans";
}
.testimonial-slider-container
  .slick-slider
  .slick-list
  .slick-slide
  div
  .MuiCard-root {
  max-width: 100%;
}

/* Homepage slider end */
.products-arrow-next {
}

.d-flex {
  display: flex;
}

.mt-8 {
  margin-top: 8px;
}

.mt-5 {
  margin-top: 5px;
}

/* html {
  overflow-x: hidden;
} */

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-30 {
  padding: 30px;
}

.m-0 {
  margin: 0 !important;
}

body {
  background-color: var(--primary-background-color);
}

.fluid-container {
  padding-left: 5%;
  padding-right: 5%;
}

.banner {
  width: 100%;
  padding-top: 100px;
}

.pt-40 {
  padding-top: 40px !important;
}

.banner-image {
  width: 100%;
  cursor: pointer;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700 !important;
  font-style: normal;
}
.dmsans-bold {
  font-family: "DM Sans" !important;

  font-weight: 700 !important;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.section-title {
  text-align: center;
  font-size: 40px;
  padding-top: 50px;
  padding-left: 15%;
  padding-right: 15%;
}

.section-subtitle {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  padding-top: 30px;
}

.nav-tabs {
  border-bottom: 1px solid #000000;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-width: 0;
  background-color: var(--primary-background-color);
}

.nav-tabs > li {
}

.nav-tabs > li > a {
  border: none;
  color: #666;
  text-align: center;
}

.nav-tabs > li > a {
  color: #000000;
}

.nav-tabs > li.active > a {
  border: none !important;
  color: #000 !important;
  background: var(--primary-background-color);
  font-weight: bolder;
}

.nav-tabs > li > a:hover {
  border: none !important;
  color: #000000 !important;
  background: #fff;
}

.nav-tabs > li > a::after {
  content: "";
  height: 6px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
  border-radius: 20px;
}

.nav-tabs > li.active > a::after {
  transform: scale(1);
  background: #000;
}

.nav-tabs > li:hover > a::after {
  transform: scale(1);
  background: #000;
}

.tab-pane {
  padding: 15px 0;
}

.tab-content {
  padding-top: 30px;
  width: 100%;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}

.card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

.flex-align-center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.container {
  width: 100% !important;
  /* padding-left: 5% !important;
    padding-right: 5% !important; */
}

.contact-us-container {
  padding: 0 !important;

}

.card {
  box-shadow: none !important;
  background-color: inherit;
  background: inherit;
}

.pt-50 {
  padding-top: 50px;
}

.flex-col {
  flex-direction: column;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: #182023 !important;
}
.MuiPopover-root {
  top:66px !important
}

.space-around {
  justify-content: space-around;
}

.content-section {
  background: linear-gradient(180deg, #8c2652 -6.25%, #226bac 75%);
  width: 570px;
  height: 570px;
  padding: 50px;
}
.addressText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.w-80 {
  width: 80% !important;
}

.w-33 {
  width: 33% !important;
}

.w-60 {
  width: 60% !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.faq-container {
  display: flex;
  flex-direction: row;
}

.faq-tabs-container {
  display: flex;
  width: 40%;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
}

.w-90 {
  width: 80% !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
  line-height: 24px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-22 {
  font-size: 22px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.containerwidth {
  width: 100%;
}

.wrapper {
  background-color: inherit;
  padding: 10px 20px;
  border-bottom: 1px solid #b9bdc3;
}

.w-25 {
  width: 25% !important;
}

.toggle,
.content {
  font-family: "Poppins", sans-serif;
}

.toggle {
  width: 100%;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  font-family: var(--secondary-text);
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  color: #111130;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 0;
}

/* Pagination Bullets */
.swiper-pagination {
  bottom: 10px; /* Adjust positioning if necessary */
}

/* Default Bullet Styles */
.swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.5); /* Default bullet color */
  opacity: 0.7; /* Slightly transparent */
  width: 14px !important; /* Set width */
  height: 14px !important; /* Set height for consistency */
  border-radius: 16px; /* Set border radius */
  margin: 0 4px; /* Add some margin between bullets */
  transition: background 0.3s; /* Smooth transition for hover/active states */
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: none !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: none !important;
}

.swiper-pagination {
  position: sticky !important;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  padding-top: 30px;
}

/* Active Bullet Styles */
.swiper-pagination-bullet-active {
  background: #db1f51 !important; /* Active bullet color */
  opacity: 1; /* Fully opaque */
  width: 14px !important;
  height: 14px !important;
}

/* Optional: Hover State */
.swiper-pagination-bullet:hover {
  opacity: 1; /* Fully opaque on hover */
}

.content {
  position: relative;
  font-size: 14px;
  text-align: justify;
  line-height: 16px;
  height: 0px;
  width: 90%;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-family: var(--primary-text);
}

/* form content */

.content-title {
  font-family: Poppins;
  font-size: 50px;
  font-weight: 400;
  line-height: 66px;
  text-align: left;
  color: #ffffff;
}

.content-headoffice {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding-top: 30px;
  color: #ffffff;
}

.button-class {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: white;
  padding: 15px 30px;
  border-radius: 80px;
  border: none;
  background: linear-gradient(
    -240deg,
    #2b9cff 11%,
    #0066c1 33%,
    #00417b 68%,
    #c33b6f 83%
  );

  /* margin-top: 20px; */
}
.buttonTransparent-class {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: white;
  padding: 10px 30px;
  border-radius: 80px;
  border: none;
  background: transparent;
  border: 1px solid white;
  /* margin-top: 20px; */
}
.button-secondary-class {
  background-image: linear-gradient(to right, #006175 0%, #00a950 100%);
  border-radius: 40px;
  box-sizing: border-box;
  color: #00a84f;
  display: block;
  font: 1.125rem "Oswald", Arial, sans-serif;
  /*18*/
  height: 80px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 15px 30px;
  border-radius: 80px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  height: 80px;
  width: 264px;
  z-index: 2;
}

.content div div img {
  width: 20px !important;
}

.secondary-button {
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(92.08deg, #273356 0.69%, #e1345d 120.93%) border-box;
  color: #232323;
  padding: 15px 30px;
  border: 0.68px solid transparent;
  border-radius: 80px;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-left: 20px;
  height: 60px;
  position: relative;
  padding: 15px 30px;
}

/* .secondary-button:hover {
	color: #fff;
}

.secondary-button span {
	align-items: center;
	background: white;
	border-radius: 80px;
	display: flex;
	justify-content: center;
	height: 100%;
	transition: background .5s ease;
	width: 100%;
} */

.btn:hover span {
  background: transparent;
}

.mt-30 {
  margin-top: 30px !important;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.button-secondary-class::before {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border: 0.68px solid;
  border-radius: 80px;
  z-index: -1;
}

.pt-30 {
  padding-top: 30px;
}

.p-40 {
  padding: 40px;
}

.w-100 {
  width: 100% !important;
}

.pb-30 {
  padding-bottom: 30px;
}

.color-white {
  color: white !important;
}

.industry-select-container {
  /* padding-top: 60px; */
}

.bg-white {
  background-color: white !important;
}

.career-banner:nth-child(odd) {
  margin-top: 90px;
  margin-left: 30px;
  margin-right: 30px;
}

.images-wrapper {
  white-space: nowrap;
  overflow: visible;
  display: flex;
  animation: marquee 30s linear infinite;
}

.marquee {
  white-space: nowrap;
  overflow: visible;
  display: flex;
  animation: marquee 42s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.images-wrapper img {
  display: inline-block;
}

.scrolling-container {
}

.z-index-9 {
  z-index: 99999 !important;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.product-card {
  border: 1px solid #ded7cd;
}

.product-banner-title {
  font-family: Poppins, sans-serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 66px;
  text-align: left;
}

.product-card-title {
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
}

.product-card-subtitle {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 80%;
}

.product-usecase-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.usecase-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

.text-align-left {
  text-align: left;
}

.grid-1 {
  padding: 12px;
  border: 1px solid var(--primary-tab-color);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.details-title {
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
}

.usp-title {
  font-family: Poppins, sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 49.55px;
  letter-spacing: -0.02em;
  text-align: center;
  justify-content: center;
}

.usp-card-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.84px;
  text-align: left;
}

.p-6 {
  padding: 6px;
}

.border-primary {
  border: 1px solid #cfccc4;
}

.usp-card-subtitle {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.details-description {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.grid-2 {
  padding: 12px;
  border: 1px solid var(--primary-tab-color);
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.industry-card-containers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 40px;
}

.industry-card-detail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.industry-card {
  margin: 20px;
  padding: 6px;
  border: 1px solid #a19c9c;
  display: flex;
  flex-direction: column;
}

.industry-image {
  height: 253px;
}

.industry-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.84px;
  text-align: left;
}

.industry-description {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  /* line-height: 28.84px; */
  text-align: left;
}
.blogTags {
  width: auto;
  height: 25px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.blog-description {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 28.84px; */
  text-align: left;
  display: -webkit-box;          /* Create a flexible box layout */
  -webkit-box-orient: vertical; /* Set the box orientation to vertical */
  -webkit-line-clamp: 2;        /* Limit the text to 2 lines */
  overflow: hidden;              /* Hide overflow content */
  text-overflow: ellipsis;      /* Show ellipsis for overflowed text */
  height: 3em;                  
}

.p-20 {
  padding: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.industry-content-bold {
  font-family: Poppins, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #616161;
}

.industry-content-light {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #616161;
}

.card-button-class {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  background: linear-gradient(
    -240deg,
    #2b9cff 11%,
    #0066c1 33%,
    #00417b 68%,
    #c33b6f 83%
  );
  padding: 12px 24px;
  border: none;
  border-radius: 80px;
  color: white;
}

.card-button-secondary-class {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: white;
  padding: 12px 24px;
  border-radius: 80px;
  border: 1px solid transparent;
  background: transparent;
  color: #232323;
  position: relative;
  background: linear-gradient(92.08deg, #273356 0.69%, #c33b6f 120.93%);
  outline: none;
  margin-right: 10px;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(92.08deg, #273356 0.69%, #e1345d 120.93%) border-box;
}

.z-index-9 {
  z-index: 999 !important;
}

.heading {
  font-size: 23px;
  color: #2a2a2a;
}

.big-heading {
  font-size: 40px;
  font-family: Poppins, sans-serif;
  color: #2a2a2a;
}

.info-container {
  transform: rotate(90deg);
  position: relative;
  left: -500px;
  bottom: calc(100vw - 300px);
}

@media screen and (max-width: 1921px) {
  .carousel {
    height: 700px !important;
    top: -701px !important;
    left: 45% !important;
    position: absolute !important;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .info-container {
    transform: rotate(90deg);
    position: relative;
    left: -660px;
    bottom: calc(100vw - 698px);
  }
}

@media screen and (max-width: 1850px) {
  .carousel {
    height: 700px !important;
    top: -701px !important;
    left: 45% !important;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .info-container {
    transform: rotate(90deg);
    position: relative;
    left: -660px;
    bottom: calc(100vw - 521px);
  }
}

@media screen and (max-width: 1800px) {
  .carousel {
    height: 700px !important;
    top: -701px !important;
    left: 42% !important;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .info-container {
    transform: rotate(90deg);
    position: relative;
    left: -660px;
    bottom: calc(100vw - 667px);
  }
}

@media screen and (max-width: 1700px) {
  .carousel {
    top: -610px !important;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .info-container {
    left: -570px;
    bottom: calc(100vw - 574px);
  }
}

@media screen and (max-width: 1600px) {
  .carousel {
    top: -75% !important;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .info-container {
    left: -500px;
    bottom: calc(100vw - 570px);
  }
}

@media screen and (max-width: 1450px) {
  .carousel {
    top: -75% !important;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .info-container {
    left: -36%;
    bottom: calc(100vw - 492px);
  }
}

@media screen and (max-width: 1400px) {
  .fluid-container {
    padding-left: 2%;
    padding-right: 2%;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    width: 100%;
    display: contents;
  }

  .carousel {
    top: -70% !important;
    left: 40% !important;
  }

  .info-container {
    bottom: calc(100vw - 436px);
    left: -34%;
  }
}

.product-listing-card {
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  transition: transform 0.3s;
  overflow: hidden;
  width: 280px;
}

.product-listing-card:hover {
  /* transform: translateY(-10px); */
  border: 1px solid rgba(219, 31, 81, 1);
  /* Change border color on hover */
}

.product-listing-card .product-listing-card-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  transition: transform 0.3s;
  display: flex;
  justify-content: left;
  font-size: 16px;
}

.product-listing-card .hover-buttons {
  opacity: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  transition: opacity 0.3s;
}

.product-listing-card:hover .product-listing-card-title {
  transform: translateY(-40px);
}

.product-listing-card:hover .hover-buttons {
  opacity: 1;
}

.enquire {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: white;
  padding: 0;
  border-radius: 80px;
  border: none;
  background: linear-gradient(90deg, #2b9cff 0%, #0066c1 34%, #c33b6f 100%);
  margin-top: 20px;
  height: 41px;
  width: 115px;
  padding: 10px 10px;
}
.fullWidth {
  width: 100% !important;
}
.view-product {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: white;
  padding: 0;
  border-radius: 80px;
  border: none;
  background: linear-gradient(90deg, #2b9cff 0%, #0066c1 34%, #c33b6f 100%);

  height: 38px;
  width: 150px;
  padding: 10px 10px;
}
.view-more {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100%;
  color: white;
  padding: 0;
  border-radius: 80px;
  border: none;
  background: white;
  margin-top: 20px;
  height: 41px;
  width: 100px;
  padding: 10px 10px;
  border: 1px solid rgba(225, 52, 93, 1);
}

.css-1sinza1-MuiPaper-root-MuiAccordion-root {
  font-family: "Poppins";
  /* border-bottom: 1px solid rgba(184, 181, 176, 1); */
  border-bottom-width: 20px;
  box-shadow: none !important;
}

.solution-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 49.55px;
  letter-spacing: -0.02em;
  text-align: center;
  padding-top: 30px;
}

.solution-image {
  width: 100%;
}

.solution-relative-text {
  position: relative;
  top: -70px;
  color: #ffffff;
  left: 20px;
}

.solution-image-subtitle {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.solution-main-image-title {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31.85px;
  letter-spacing: -0.02em;
  text-align: left;
}

.solution-description {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  position: relative;
  top: -30px;
}

.css-1sinza1-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.css-1sinza1-MuiPaper-root-MuiAccordion-root {
  border-bottom-width: 1px;
  box-shadow: none !important;
}

.list-style {
  color: rgba(120, 120, 120, 1);
  font-size: 14px;
  font-family: "Poppins";
}

.carousel-item {
  display: inline-block;
  /* Ensures items are laid out in a line */
  margin-right: 10px;
  /* Adjusts spacing between items */
}

.carousel-content {
  height: 200px;
  width: 400px;
  border-width: 1px;
  background-color: #eef2f7;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
 
}

.react-multi-carousel-list {
  display: flex;
  align-items: baseline !important;
  overflow: hidden;
  position: relative;
}

.slider-heading {
  z-index: 9;
  background-color: rgb(238, 242, 247);
  width: 34px;
  position: absolute;
  left: 300px;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  white-space: nowrap;
  transform: rotate(180deg);
  transform-origin: center;
  height: 400px;
  text-align: center;
}

.carousel-container {
  height: fit-content;
}

.react-multi-carousel-item {
  width: 100% !important;
}

.carousel-container {
  white-space: nowrap;
  /* Ensures items are aligned horizontally */
  overflow-x: auto;
  /* Allow horizontal scrolling */
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  width: 90%;
  margin-left: 30px;
}

.carousel-item {
  display: inline-block;
  /* Ensures items are laid out in a line */
  margin-right: 10px;
  /* Adjusts spacing between items */
}

.carousel-content {
  height: 400px;
  width: 100%;
  border: 1px solid #eef2f7;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  /* margin-right: 120px; */
}

.custom-arrow {
  display: block !important;
  background: white !important;
  z-index: 9999 !important;
  height: 64px !important;
  width: 64px !important;
  border-radius: 100% !important;
}

.carousel-prod {
  background: radial-gradient(50% 50% at 50% 50%, #96c8f3 0%, #1e66a5 100%);
}

.carousel-prod-red {
  background: radial-gradient(50% 50% at 50% 50%, #ff799c 0%, #db1f51 100%);
}

.MuiCard-root {
  background-color: #ffffff !important;
}

.card-padding {
  padding: 30px;
}

.usecase-grid {
  display: none;
}

.product-usecase-title {
  display: none !important;
}

.gradLine {
  border: 1px solid;
  /* set the border width */
  border-image: linear-gradient(92.08deg, #e232bc 0.69%, #3558d6 77.81%);
  border-image-slice: 1;
}

.top-20 {
  top: 20px !important;
}

.banner-class {
  padding-top: 40px !important;
}

.secondary-button {
  margin-top: 20px;
}

.button-arrow-padding {
  padding-right: 10px;
}

.banner-image {
  /* padding-top: 40px !important; */
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root:hover
  .MuiCardActionArea-focusHighlight {
  opacity: 0 !important;
}

@media screen and (max-width: 1100px) {
  .industry-card-containers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 820px) {
  .section-title {
    font-size: 24px;
    text-align: center;

    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }
  .discoverNow {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
  }
  .card-button-class {
    padding: 8px 16px;
  }

  .card-button-secondary-class {
    padding: 8px 16px;
  }

  .details-container {
    flex-direction: column;
  }

  .details-image-container {
    width: 100%;
    height:212px
  }

  .details-content-container {
    margin: 0;
    width: 100%;
  }

  .details-title {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    padding-top: 20px;
  }

  .usp-title {
    flex-direction: column;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 49.55px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .content-title {
    font-size: 26px;
    font-weight: 400;
    line-height: 66px;
    letter-spacing: -0.03em;
    text-align: left;
    font-family: "DM Sans";
  }

  .col-md-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .banner {
    padding-top: 0;
  }

  .col-md-6 {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .poppins-regular {
    line-height: 18px;
  }

  .content-headoffice {
    font-size: 18px;
    padding-top: 0;
    font-family: "Poppins";
  }

  .tab-content {
    padding-top: 0;
  }

  .nav-tabs {
    white-space: nowrap;
    overflow-x: auto;
    width: 100vw !important;
    overflow-y: hidden;
  }

  .nav-tabs > li {
    width: 100% !important;
  }

  .product-card-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.25px;
    text-align: left;
  }

  .product-usecase-title {
    padding-top: 20px !important;
    display: none;
  }

  .usecase-grid {
    display: none;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .card-padding {
    padding: 10px;
  }

  .button-arrow-padding {
    padding-right: 3px;
  }

  .button-class {
    font-size: 14px;
    padding: 8px 16px;
    height: 40px;
  }

  .secondary-button {
    font-size: 14px;
    padding: 8px 16px;
    height: auto;
    margin-top: 0;
    height: 40px;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
  }

  .pt-50 {
    padding-top: 20px;
  }

  .banner-class {
    padding-top: 40px !important;
  }

  .industry-card-containers {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-wrapper {
    flex-direction: column;
  }

  .content-section {
    width: 100%;
    order: 2;
    height: auto;
    background: linear-gradient(180deg, #8c2652 -6.25%, #226bac 75%);
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
  }
  .addressText {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 18px;
    text-align: left;
  }

  .fields-section {
    width: 100% !important;
    height: auto !important;
    order: 1;
  }

  .form-container {
    padding-top: 10px !important;
  }

  .form-wrapper {
    width: 100% !important;
  }

  .fluid-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .mobile-usps {
    display: flex !important;
    flex-direction: row;
    white-space: nowrap;
    overflow: auto;
  }

  .usp-title {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 49.55px;
    letter-spacing: -0.02em;
    text-align: center;
    flex-direction: row;
  }

  .mobile-usps div {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .product-banner-title {
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }
  .top-slider .slick-slider .slick-list .slick-slide div .MuiCard-root {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    display: contents !important;
  }
  .usp-card-title {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28.84px;
    text-align: left;
  }
  .usp-card-subtitle {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
  .mobile-usps {
    display: flex !important;
    flex-direction: column;
    white-space: normal;
    overflow: auto;
    height:400px;

  }
}

.d-flex:hover img {
  /* transform: rotate(-410deg); */
  transition: transform 0.3s ease-in-out; /* Optional: Smooth rotation */
}

@media only screen and (max-width: 768px) {
  .font-16 {
    font-size: 14px !important;
  }
  .nav-tabs {
    display: flex;
    text-align: center;
    margin-left: 10px;
  }

  .nav-tabs > li {
    display: flex;
    margin: 0 10px;
  }

  /* Ensures the active tab is centered */
  .nav-tabs > li.active {
    margin: 0 auto;
  }

  /* Optional: Adjust the space for smaller devices */
  .nav-tabs > li {
    width: auto;
  }
 
}

@media screen and (min-width: 3500px) {
}

/* .slick-initialized{
  position: absolute;
    left: 10% !important;
    top: 41% !important;
    width: 100%;
    height: 400px;
} */

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}

.discoverNow {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: left;
}

.icon {
  pointer-events: auto; /* Ensure that the icon is clickable */
}

.css-4pdmu4-MuiBreadcrumbs-ol {
  display: flex !important;
  align-items: baseline !important;
}

.slick-prev:before {
  content:none !important;
}
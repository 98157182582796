.about-banner-image {
  width: 100%;
  cursor: pointer;
}

.big-bold {
  font-size: 40px;
  line-height: 49.55px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #2a2a2a;
}

.big-regular {
  font-size: 40px;
  line-height: 49.55px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #2a2a2a;
}

.font-300 {
  font-weight: 300;
}

.small-regular {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #2a2a2a;
}

.line-height {
  line-height: 5;
}

.width-457 {
  width: 457px;
}

.clients-team {
  height: 110px;
  width: 30%;
  background: #eef2f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.team-count {
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #015198;
  /* line-height: 5; */
}

@media (max-width: 600px) {
  .team-count {
    font-size: 26px; /* Adjust this value as needed */
  }
}

.font-16-text {
  font-size: 16px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

.horizontal-floating-line {
  width: 213px;
  height: 6px;
  position: relative;
  background-color: #db1f51;
  right: -70%;
  bottom: 10px;
  border: none;
}

.brief-uni {
  background: linear-gradient(101.84deg, #db1f51 69.19%, #015198 105%);
  /* background: #015198 !important; */

  padding: 25px;
  text-align: center;
  position: absolute;
  top: 100px;
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  align-items: center;
  display: flex;
  left: 38%;
}

.product-card-container {
  border: 1px solid #ded7cd;
  background: #eef2f7;
}

.product-card-subContainer {
  position: absolute;
  top: 30px;
  right: -20px;
}

.color-primary {
  color: #2a2a2a;
}

.neterwala-group-container {
  background-color: #0b487e;
  height: 350px;
  width: 500px;
  display: flex;
  align-items: end;
}
@media (max-width: 600px) {
  .neterwala-group-container {
    /* width: 200px; */
    margin: 20px;
  }
}

.extra-big {
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
}

.neterwala-group-subcontainer {
  background-color: #eef2f7;
  height: 200px;
  display: flex;
  justify-content: space-around;
}

.dottedLine-class {
  border-left: 2px dotted #c2c2c2;
  height: 150px;
}

.width-200 {
  width: 200px;
}

.box {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AboutImg-class {
  height: 81px;
  width: 81px;
}

.industries-worldwide-container {
  background-color: #0b487e;
  width: 555px;
  height: 300px;
  position: absolute;
  left: 45%;
  top: 35px;
  text-align: center;
  border: 1px solid rgba(131, 127, 127, 0.29);
}

@media (max-width: 600px) {
  .industries-worldwide-container  {
    padding-top: 25px;
    /* height: 350px; */
  }
}

.industries-text {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.14em;
  text-align:left;
}

.horizontal-line {
  width: 133px;
  height: 4px;
  background-color: #db1f51;
}

.color-white {
  color: #ffffff;
}

.font-46 {
  font-size: 46px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.color-blue {
  color: #015198;
}

.color-red {
  color: #db1f51;
}

.box-wrapper {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 10s linear infinite;
  display: flex;
}

.font-16-regular {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #2a2a2a;
}

.scroller-box {
  height: 130px;
  width: 320px;
  background: #eef2f7;
}

/* .right-back-container{
    height: 64px;
    width: 64px;
    border: 3px solid #000000;
    background-color:#1E1D1C;
}
.left-back-container{
    height: 64px;
    width: 64px;
   box-shadow: 4px 4px 20px 0px #0973D840;
    background-color: #FFFFFF;
    right: 0;
    transform: translateY(-50%) rotate(180deg);
} */

/* .container { */
  /* margin: 0 auto;
    width: 700px;
    height: 80vh;
    border-color: green; */
/* } */

.slick-next {
  background-color: black;

  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

h1 {
  text-align: center;
  margin: 3rem 0;
}

li {
  list-style: none;
}

body {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

.wiper-track {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Adjust according to the number of items */
  gap: 70px;
  margin: 0 auto;
  transition: transform 0.4s ease-in;
  padding: 0px 30rem;
  transform: translateX(calc(-50% + 100px)); /* Start from the middle item */
}

 .active-swipe{
  width: 378px;
  height: 320px;
  transform: scale(1.1);  /* Zoom in the active swipe */

  position: relative;
  transition: scale 0.2s linear;
  border-width: 1px;
  border-color: #cfccc4;
  padding: 6px;
}

.wiper-item {
  height:auto;
  transition: scale 0.2s linear;
  border-width: 1px;
  border-color: #cfccc4;
  padding: 8px;
  background-color: white;
}

.slick-center .wiper-item{
  scale: 1.3;
  transition: scale 0.2s linear;
  position: relative;
  z-index: 99;
}

.box-section-title {
  color: #486284;
  font-family: Poppins, sans-serif;
  font-size: 18px !important;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.wiper-button img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: 4px 4px 20px 0px #0973d840;
}

.wiper {
  position: relative;
  margin: 0 auto;
  padding-top: 20px;
}

.active-swipe {
  transform: scale(1.1);
}

.wiper-button {
  margin: 0 -100px;
  height: 64px;
  width: 64px;
  position: absolute;
  top: 50%;
  background: transparent;
  border: none;
  box-shadow: 4px 4px 20px 0px #0973d840;
  transform: translateY(-50%);
  z-index: 1;
}

.wiper-button-left {
  margin: 0 -100px;
  height: 64px;
  width: 64px;
  position: absolute;
  top: 50%;
  box-shadow: 0px -10px 31px 0px #0973d840;
  background: transparent;
  background-color: white;
  border: none;
  transform: translateY(-50%);
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wiper-button-right {
  margin: 0 -100px;
  height: 64px;
  width: 64px;
  position: absolute;
  top: 50%;
  transform: rotate(70deg) translateY(-80%);
  rotate: 30;
  box-shadow: 0px -10px 31px 0px #0973d840;
  background: transparent;
  background-color: white;
  border: none;
  z-index: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wiper-button__left {
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}

.wiper__image-button {
  right: 50%;
  top: 50%;
  position: absolute;
  padding: 0.8rem 1rem;
  border-radius: 3px;
  background-color: white;
  border: none;
  font-size: 1.4rem;
  transform: translateY(-50%) translateX(50%);
  cursor: pointer;
}

.wiper-wrapper {
  max-width: 100%;
  overflow: hidden;
  padding: 2rem 0.5rem;
  margin: 0 auto;
}

.swiper__image-button {
  right: 50%;
  top: 50%;
  position: absolute;
  padding: 0.8rem 1rem;
  border-radius: 3px;
  background-color: white;
  border: none;
  font-size: 1rem;
  transform: translateY(-50%) translateX(50%);
  cursor: pointer;
}

.swiper__image-button:hover {
  color: red;
}

.wiper__image {
  height: 100%;
  width: 100%;
  border-color: white;
  overflow: hidden;
  background-color: #e7ecf4;
}
.wiper__image img{
  mix-blend-mode: multiply;
  opacity: 0.8;
}

.is-hidden {
  /* display: none; */
  opacity: 0.5;
  cursor: not-allowed;
}

.bg-black{
  background-color: #000;
  color:#FFF
}

.resources-card {
  border: 1px solid #ded7cd;
  border-radius: 6px;
  background: #eef2f7;
}

.neterwala-group-subcontainer {
  position: relative;
}

@media (max-width: 820px) {
  .wiper {
    position: relative;
    margin: 0 auto;
    padding-top: 20px;
    height:350px
  }
  .wiper-track{
    padding: 0px 3rem;
    gap: 40px;
  }
  .about-us-title {
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 49.55px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .about-us-description {
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .product-card-container {
    position: static;
  }

  .about-us-mini-description {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: center;
    padding-top: 20px;
  }

  .clients-team {
    height: 110px;
    width: 50%;
    background: #eef2f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }

  .brief-uni {
    background: linear-gradient(101.84deg, #db1f51 69.19%, #015198 105%);
    height: 120px;
    /* background: #015198 !important; */
    padding: 25px;
    text-align: center;
    position: absolute;
    font-size: 23px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    justify-content: center;
    display: flex;
    left: 0px;
    top: 79px;
    width: 100%;
  }

  .product-card-subContainer {
    width: 100%;
    right: 0;
    top: 0;
    padding-right: 5%  !important;
    padding-left: 5%  !important;

  }

  .neterwala-group-subcontainer {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
  }

  .dottedLine-class {
    border-bottom: 2px dotted #c2c2c2;
    width: 200px;
    height: 0px;
  }

  .neterwala-group-container {
    width: 85%;
    height: 200px;
  }

  .extra-big {
    font-size: 30px;
  }

  .horizontal-floating-line {
    width: 140px;
    height: 6px;
    position: relative;
    background-color: #db1f51;
    right: -70%;
    bottom: 5px;
    border: none;
  }
  /* .col-md-6{
    padding-left: 16px ;
        padding-right: 16px ;
  } */
  .industries-worldwide-container {
    background-color: #0b487e;
    width: 100%;
    height: 350px;
    left: 45%;
    top: 25px;
    /* text-align: center; */
    border: 1px solid rgba(131, 127, 127, 0.29);
    position: initial;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .industries-text {
    font-size: 18px;
    font-weight: 500;
    font-family: "DM Sans";
    color: #ffffff;
    letter-spacing: 0.14em;
    text-align: center;
   
  }

  .industries-description {
    /* padding-top: 40px !important; */
   
    text-align: center;
    font-size: 14px;
    line-height: 24px !important;
  }


  .horizontal-line {
    width: 133px;
    height: 4px;
    background-color: #db1f51;
    text-align: center;
    /* margin-top: 40px; */
    /* margin-left: 30% !important; */
  }

  .product-card-subContainer {
    position: static !important;
  }

  .vision-title {
    font-family: Poppins, sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 33.85px;
    text-align: left;
   
  }

  .vision-description {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding-top: 20px;
    /* padding-left: 20px;
    padding-right: 20px; */
  }

  .vision-description:last-child {
    padding-bottom: 30px;
  }

  .font-46 {
    font-size: 28px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    line-height: 30px;
    padding: 10px;
  }

  .font-16-regular {
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #2a2a2a;
    padding-left: 10px;
  }

  .wiper-button-left {
    margin: 0 -100px;
    height: 64px;
    width: 64px;
    /* position: absolute; */
    left:50%;
    top: 100%;
    background: transparent;
    background-color: white;
    border: none;
    transform: translateY(-80%);
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .wiper-wrapper {
    width: 100%  !important;
    overflow: visible;
    padding: 2rem 0.5rem;
    margin: 0 auto;
  }
  .wiper-button-right {
           /* margin: 0 -100px; */
           height: 64px;
           width: 64px;
           /* position: absolute; */
           right: 50%;
           top: 100%;
           transform: rotate(70deg) rotate(30deg);
           box-shadow: 0px -10px 31px 0px #0973d840;
           background: transparent;
           background-color: white;
           border: none;
           transform: rotate(180deg);
           z-index: 1;
           text-align: center;
           display: flex;
           align-items: center;
           justify-content: center;
  }

  .wiper-button__left {
    /* right: 0; */
    transform: translateY(-85%) rotate(180deg);
  }

  .neterwala-description {
    padding-top: 20px;
    padding-left: 0 !important;
    padding-right: 5%;
  }

  .neterwala-group-subcontainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  .tab-content {
    margin-left: 0;
  }

  .global-footprint-title {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 49.55px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .engineer-image {

    width: 350px !important;
    height: 260px !important;
  }
  @media only screen and (max-width: 768px) {
    .engineer-image {
      width: 100% !important;
      height: 250px !important;
    }
  }
  

  .color-blue {
    font-family: Poppins, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    padding-left: 0 !important;
    padding-bottom: 10px !important;
  }

  .global-footprint-container {
   
    padding-top: 0 !important;
  }

  .global-footprint-content {
    padding-left: 5% !important;
    width: 100% !important;
    padding-top: 0 !important;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }

  .dottedLine-class {
    width: 320px !important;
  }

  .global-footprint-image {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .global-footprint-image {
    width: 100% !important;
  }

  .engineer-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .color-red {
    font-family: Poppins, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .brief-uni {
    /* left: 12%;
    top: 100px; */
    background: linear-gradient(101.84deg, #db1f51 69.19%, #015198 105%);

    /* background-color: #015182 !important; */
  }
}


.pdf-popup{
  background-color: white;
  border-radius: 12px;
  padding:20px;
  text-align: center;

}

ul li{
  list-style: none !important;
}
.padding-none{
  padding: 0px !important;
}

.reports-listing-card{
width: 100%;
height:235px;

border: 1px solid rgba(0, 0, 0, 0.2);
position: relative;
transition: transform 0.3s;
overflow: hidden;
background-color: #EEF2F7;
}

.reports-title{
  font-family: Poppins, sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 28px;
text-align: left;

}
.reports-description{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}
.certifications-slider-container{
  display: flex;
  justify-content: center;
  position: relative;
}
.certifications-slider{
  max-width: 350px;
  height: 100%;
}
.certifications-slider .slick-slider{
  position: unset !important;
}

.top-banner-slider{
  width: 100%;
}
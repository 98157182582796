.carousel-solution-title {
    font-family: Poppins, sans-serif;
    font-size: 46.13px;
    font-weight: 700;
    line-height: 49.55px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
}

.carousel-solution-description {
    font-family: DM Sans,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    padding-top: 18px;
}
.footer-container {
  background-image: url("../../assets/images/watermark.svg");
background-repeat: no-repeat;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-top:10px
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #71736B73;
  height: 400px;
}

.footer-content-column {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.logo-column {
  width: 35%;
  padding-left: 16px;
}

.watermark-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.links-column {
  width: 40%;
  display: flex;
  justify-content: space-around;
}

.imageLogo {
  height: 400px;
}

.watermarkLogo{
  position: absolute;
  top:20px;
  width:600px;
}

.footer-logo {
  text-align: left;
  align-self: start;
  padding-top: 20px;
}

.footer-logo img {
  width: 132px;
}

.footer-heading {
  margin-top: 20px;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgba(41, 41, 41, 1);
}

.follow-us-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
}

.follow-text {
  padding-right: 12px;
}

.ml-5 {
  margin-left: 5px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.footer-links a {
  color: rgba(41, 41, 41, 1);
  text-decoration: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 5px 0;
}
.footer-links button {
  color: rgba(41, 41, 41, 1);
  text-decoration: none;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 5px 0;
}

.footer-text04,
.footer-text06,
.footer-text08,
.footer-text10,
.footer-text12,
.footer-text14,
.footer-text16 {
  margin: 5px 0;
}

.footer-copyright {
  text-align: center;
  padding: 20px 0;
}

.footer-description {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24.51px;
  text-align: left;
  bottom: 200px;
  margin-top:20px
  /* position: relative; */
}

@media screen and (min-width: 900px) {

  .footer-logo img {
    position: relative !important;
    /* top: -50px !important; */
  }

  .footer-heading {
    position: relative !important;
    left: -35% !important;
    top: 30% !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1440px) {

  .footer-logo img {
    position: relative !important;
    /* top: -50px !important; */
  }

  .footer-heading {
    position: relative !important;
    left: -35% !important;
    top: 30% !important;
  }
}
/* Responsive Styles */
@media (max-width: 768px) {
  .imageLogo {
    height: auto;
  }
  .footer-container {
    /* margin-top: 50px; */
    padding-left: 20px;
    padding-right: 20px;
    background-image: url("../../assets/images/footer-sm-bg.png");
    background-repeat: no-repeat;
  }

  .footer-content {
    display: block;
    /* padding-top: 20px; */
    padding-bottom: 20px;
    height: auto;
  }

  .footer-description{
    bottom:-10px;
  }

  .footer-content .logo-column {
    padding-left: 0px;
    padding-bottom: 20px;
  }

  .links-column {
    width: 40%;
    display: flex;
    justify-content: flex-start;
  }

  .footer-links {
    align-items: flex-start;
    margin-right: 30px;
  }

  .footer-links a {
    font-size: 14px;
  }

  .logo-column {
    width: 100%;
    display: flex;
    align-items: flex-start;
    mix-blend-mode: darken;
  }

  .watermark-column {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .links-column {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .watermark-column {
    display: none;
  }

  .watermark-column {
    margin: 20px 0;
  }

  .links-column {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  
  .footer-links {
    align-items: flex-start;
  }

  .footer-heading {
    font-size: 14px !important;
  }

  .footer-logo img {
    width: 100px;
    height: 50px;
  }

  .watermark-column img {
    width: 200px;
    height: auto;
  }
}


@media screen and (min-width: 1500) {
  
  .footer-heading {
      position: relative !important;
      left: -19% !important;
      top: 30% !important;
  }
}
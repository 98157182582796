/* Styling for Privacy Policy Page */

.privacy-policy {
    /* margin: 120px auto 120px auto; */
    background-color: #fff;
    margin-top:100px

}

.section {
    margin-bottom: 40px;
}

h2 {
    font-family: Poppins, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: left;
    color: #2A2A2A;
    padding-bottom: 20px;
}

p {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #2A2A2A;
    padding-bottom: 10px;
}

a {
    /* color: #007bff; */
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    /* color: #0056b3; */
}

ul {
    list-style-type: disc !important;
    padding-left: 20px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #2A2A2A;
}

ul li {
    list-style: disc !important;
}



@media (max-width: 768px) {


    h2 {
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        text-align: left;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    ul {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .privacy-policy {
        margin: 60px 0 60px 0 !important;
    }
}
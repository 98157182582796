.map-container {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .world-map {
    width: 100%;
    height: auto;
  }
  
  .marker {
    position: absolute;
    transform: translate(-50%, -50%);
  }
  
  .marker img {
    width: 20px;
    height: 20px;
  }
  
  .popup {
    display: none;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .marker:hover .popup {
    display: block;
  }
  
  .hidden {
    display: none;
  }
  
  .visible {
    display: block;
  }


  .about-description{
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .css-gxdpkv-MuiPaper-root-MuiCard-root{
    border:none !important
  }

  .swiper{
    overflow: visible !important;
  }
  .swiper-slide{
    /* width: 800px; */
    margin:0 10px
  }
  .home .swiper-wrapper {
    overflow: hidden !important
  }
  .swiper-container {
    height: 100%;
  }
  
  .home .swiper-scrollbar {
    display: none !important;
  }


  .top-banner-slider .slick-list{
    overflow: hidden;
  }
  .products-slider .swiper-wrapper{
    overflow: visible !important;
  }

  .products-slider {
    overflow: hidden;
    width: 100%; 
  }
  
  .slick-track {
    height: 220px;
}
  
.umang .slick-track {
    height: 600px;
}

@media (max-width: 1535px) { /* Tablet and below */
  .umang .slick-track {
      height: 500px;
  }
}
@media (max-width: 768px) { /* Tablet and below */
  .umang .slick-track {
      height:450px;
  }
}